.App {
  background-image: url('./assets/bg-main.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  color: #fff !important;
}

.logo{
  img{
    height: 75px;
  }
}

.container{
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 12vh;

  @media screen and (max-width: 576px) { 
    &{
      width: 80% !important;
    }
  }
}

.badge{
  background-color: #FDE9A4;
  padding: 10px;
  border-radius: 30px;
  color: black;
  font-weight: normal;
  margin-bottom: 40px;
}


h6{
  font-size: 0.8em;
  text-decoration: underline;
}

.wallet-adapter-button{
  margin-bottom: 10px !important;
}

input[type="text"]{
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: none;
}

.footer{
  margin-top: auto;
  width: 100%;
  display: flex;

  img{
    height: 50px;
    margin-left: auto;
  }
}